"use strict";

var _interopRequireDefault = require("D:/www/xinshengdai/xiehui/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("D:/www/xinshengdai/xiehui/back-end/app/firm/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _role = require("@/api/role");
var _tree = _interopRequireDefault(require("@/utils/tree"));
var _editForm = require("./editForm");
var _vuex = require("vuex");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default2 = {
  name: 'Edit',
  components: {},
  props: {
    parents: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      temp: {},
      model: {},
      formRule: _editForm.formRule,
      formOption: _editForm.formOption,
      btnLoading: false,
      modelChange: false,
      formVisible: false,
      parentTop: [{
        id: 0,
        title: '顶级'
      }]
    };
  },
  computed: {
    parentTree: function parentTree() {
      return this.parentTop.concat(_tree.default.listToTree(this.parents));
    }
  },
  watch: {
    'model.form': {
      handler: function handler(val) {
        if (typeof val !== 'undefined') {
          this.modelChange = true;
        }
      },
      immediate: true,
      deep: true
    },
    formVisible: function formVisible() {
      this.handleReset();
    }
  },
  created: function created() {},
  methods: _objectSpread(_objectSpread({
    handleClose: function handleClose(done) {
      var _this = this;
      if (this.btnLoading) {
        return;
      }
      if (this.modelChange) {
        this.$confirm('更改将不会被保存，确定要取消吗？').then(function (_) {
          _this.formVisible = false;
        }).catch(function (_) {});
      } else {
        this.formVisible = false;
      }
    },
    handleReset: function handleReset() {
      var _this2 = this;
      this.temp = {};
      this.model.form && this.model.resetFields();
      this.modelChange = false;
      this.$nextTick(function () {
        _this2.model.mergeRule('pid', {
          props: {
            options: _this2.parentTree
          }
        });
      });
    },
    handleCreate: function handleCreate() {
      this.btnLoading = false;
      this.formVisible = true;
    },
    handleUpdate: function handleUpdate(id) {
      var _this3 = this;
      this.formVisible = true;
      (0, _role.getInfo)(id).then(function (response) {
        if (response.code === 0) {
          _this3.temp = response.data;
          var auths = [];
          response.data.auth.forEach(function (item) {
            if (!item.half) {
              auths.push(item.name);
            }
          });
          var parentChecked = _tree.default.getParentsId(_this3.parents, id);
          if (parentChecked.length > 0) {
            parentChecked = parentChecked.filter(function (item) {
              return item > 0;
            });
          } else {
            parentChecked = [];
          }
          _this3.model.setValue(_this3.temp);
          _this3.model.setValue('auth_tree', auths);
          _this3.model.setValue(_this3.temp);
        }
      });
    },
    handleSubmit: function handleSubmit() {
      var _this4 = this;
      this.model.submit(function (formData) {
        if (_this4.temp.id) {
          formData.id = _this4.temp.id;
        }
        if (formData.pid && Array.isArray(formData.pid)) {
          formData.pid = formData.pid.pop();
        }
        formData.auth = JSON.stringify(_this4.temp.auth);
        delete formData.auth_tree;
        _this4.btnLoading = true;
        if (formData.id) {
          (0, _role.update)(formData).then(function (response) {
            _this4.btnLoading = false;
            if (!response.code) {
              if (!_this4.temp.id) {
                _this4.temp.id = response.data.id;
              }
              _this4.$emit('updateRow', _this4.temp);
              _this4.formVisible = false;
              _this4.$message.success(response.msg);
            } else {
              _this4.$message.error(response.msg);
            }
          });
        } else {
          (0, _role.create)(formData).then(function (response) {
            _this4.btnLoading = false;
            if (!response.code) {
              if (!_this4.temp.id) {
                _this4.temp.id = response.data.id;
              }
              _this4.$emit('updateRow', _this4.temp);
              _this4.formVisible = false;
              _this4.$message.success(response.msg);
            } else {
              _this4.$message.error(response.msg);
            }
          });
        }
      });
    },
    handleChecked: function handleChecked(data, tree) {
      var halfChecked = tree.halfCheckedKeys.map(function (item) {
        return {
          half: 1,
          name: item
        };
      });
      var checked = tree.checkedKeys.map(function (item) {
        return {
          half: 0,
          name: item
        };
      });
      this.temp.auth = halfChecked.concat(checked);
    }
  }, (0, _vuex.mapActions)({
    getAuth: 'user/getAuth'
  })), {}, {
    formMounted: function formMounted() {
      var _this5 = this;
      this.getAuth().then(function (response) {
        _this5.model.mergeRule('auth_tree', {
          props: {
            data: _tree.default.listToTree(response)
          }
        });
      });
    }
  })
};
exports.default = _default2;